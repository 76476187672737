import { navigate, useIntl } from "gatsby-plugin-intl";
import * as React from "react";
import { connect } from "react-redux";
import * as UI from "../components/UI";
import { Helmet } from "react-helmet";

const ThanksPage = ({ bootLoaded, usuario, s }) => {
	const intl = useIntl();

	const [canRender, setCanRender] = React.useState(true);

	// usuario.estanco = "ABC123";
	// usuario.ganador = true;
	// usuario.premio = "1";

	React.useEffect(() => {
		if (usuario.estanco) setCanRender(true);
		else navigate("/");
	}, []);

	return (
		<UI.Layout graficos="default" background="1">
			{bootLoaded && canRender && (
				<>
					{usuario.ganador && (
						<>
							<Helmet>
								<title data-string="resultsGanadorHTMLTitle">{s.resultsGanadorHTMLTitle}</title>
							</Helmet>
							{usuario.premio === "1" && (
								<>
									<UI.Parrafo>
										<img
											src={`/img/premio1.png`}
											alt={s.resultsGanadorPremio1}
											title={s.resultsGanadorPremio1}
											style={{ width: "100%" }}
										/>
									</UI.Parrafo>
									<UI.Parrafo data-string="resultsGanadorTitle">{s.resultsGanadorTitle}</UI.Parrafo>
									<UI.Resaltado data-string="resultsGanadorPremio1">{s.resultsGanadorPremio1}</UI.Resaltado>
								</>
							)}
							{usuario.premio === "2" && (
								<>
									<UI.Parrafo>
										<img
											src={`/img/premio2.png`}
											alt={s.resultsGanadorPremio2}
											title={s.resultsGanadorPremio2}
											style={{ width: "100%" }}
										/>
									</UI.Parrafo>
									<UI.Parrafo data-string="resultsGanadorTitle">{s.resultsGanadorTitle}</UI.Parrafo>
									<UI.Resaltado data-string="resultsGanadorPremio2">{s.resultsGanadorPremio2}</UI.Resaltado>
								</>
							)}
							{usuario.premio === "3" && (
								<>
									<UI.Parrafo>
										<img
											src={`/img/premio3.png`}
											alt={s.resultsGanadorPremio3}
											title={s.resultsGanadorPremio3}
											style={{ width: "100%" }}
										/>
									</UI.Parrafo>
									<UI.Parrafo data-string="resultsGanadorTitle">{s.resultsGanadorTitle}</UI.Parrafo>
									<UI.Resaltado data-string="resultsGanadorPremio3">{s.resultsGanadorPremio3}</UI.Resaltado>
								</>
							)}
							{usuario.premio === "4" && (
								<>
									<UI.Parrafo>
										<img
											src={`/img/premio4.png`}
											alt={s.resultsGanadorPremio4}
											title={s.resultsGanadorPremio4}
											style={{ width: "100%" }}
										/>
									</UI.Parrafo>
									<UI.Parrafo data-string="resultsGanadorTitle">{s.resultsGanadorTitle}</UI.Parrafo>
									<UI.Resaltado data-string="resultsGanadorPremio4">{s.resultsGanadorPremio4}</UI.Resaltado>
								</>
							)}
							<UI.Parrafo data-string="resultsGanadorText1">{s.resultsGanadorText1}</UI.Parrafo>
							<UI.Resaltado data-string="resultsGanadorText2">{s.resultsGanadorText2}</UI.Resaltado>
							<UI.Parrafo data-string="resultsGanadorText3">{s.resultsGanadorText3}</UI.Parrafo>
							<UI.Parrafo data-string="resultsGanadorText4">
								{s.resultsGanadorText4}{" "}
								<UI.Boldy style={{ fontWeight: "bold" }}>{s.resultsGanadorText5}</UI.Boldy>
							</UI.Parrafo>
							<UI.Parrafo data-string="resultsGanadorText6">{s.resultsGanadorText6}</UI.Parrafo>
							<UI.Parrafo data-string="resultsGanadorText7" style={{ fontWeight: "bold" }}>
								{s.resultsGanadorText7}
							</UI.Parrafo>
							<UI.Spacer size="20px" />
						</>
					)}
					{!usuario.ganador && (
						<>
							<Helmet>
								<title data-string="resultsPerdedorHTMLTitle">{s.resultsPerdedorHTMLTitle}</title>
							</Helmet>
							<UI.Parrafo data-string="resultsPerdedorTitle">{s.resultsPerdedorTitle}</UI.Parrafo>
							<UI.Parrafo data-string="resultsPerdedorText2">
								<UI.Boldy>{s.resultsPerdedorText2}</UI.Boldy>
							</UI.Parrafo>
							<UI.Parrafo style={{ fontWeight: "bold" }}>{s.resultsPerdedorText3}</UI.Parrafo>
							<UI.Resaltado>{s.resultsPerdedorText4}</UI.Resaltado>
							<UI.Parrafo>{s.resultsPerdedorText5}</UI.Parrafo>
							<UI.Parrafo style={{ fontWeight: "bold" }}>{s.resultsPerdedorText6}</UI.Parrafo>
							<UI.Spacer size="20px" />
						</>
					)}
				</>
			)}
		</UI.Layout>
	);
};

const mapStateToProps = (state) => {
	const { bootLoaded, usuario } = state.app;
	const { strings: s } = state.app.boot;
	return {
		bootLoaded,
		usuario,
		s,
	};
};

export default connect(mapStateToProps)(ThanksPage);
